<template>
  <el-container :loading="restrictDetailsSetLoading">
    <el-header class="header1" height="32">
      <el-row type="flex" justify="start">
        <div class="back">
          <Icon iconName="arrow_left_white" />
          <Icon iconName="letter_a_white" />
        </div>
        <BreadCrumbs />
        <UserProfileButton /> </el-row
    ></el-header>
    <el-header class="header3 tab-bar" height="48">
      <el-tabs v-model="activeTab" @tab-click="onSplitResize">
        <el-tab-pane :label="$t('Товари')" name="templates"></el-tab-pane>
        <el-tab-pane :label="$t('Структури')" name="orgs"></el-tab-pane>
      </el-tabs>
    </el-header>
    <el-main id="mainGridContainer">
      <div ref="flex_container">
        <splitpanes class="default-theme" vertical>
          <pane min-size="10" max-size="50" size="21" ref="pane0">
            <el-header class="header3" height="48"
              ><el-row type="flex" justify="start" align="middle" style="height:48px;">
                <TIButton icon="plus_green" @onClick="createNewTemplate" :title="$t('Створити новий шаблон')" />
                <TIButton icon="edit_yellow" @onClick="editSelectedTemplate" :disabled="!selected.template" :title="$t('Редагувати шаблон')" />
                <TIButton icon="delete_red" @onClick="deleteSelectedTemplate" :disabled="!selected.template" :title="$t('Видалити шаблон')" />
                <TSeparator /> </el-row
            ></el-header>
            <ag-grid-vue
              ref="templatesTree"
              style="width: 100%; height: 100%;"
              class="ag-theme-alpine"
              :defaultColDef="defaultColDef"
              :columnDefs="columns.templates"
              :headerHeight="32"
              :context="context"
              :rowData="templatesTree"
              :modules="modules"
              rowSelection="single"
              treeData="true"
              :groupDefaultExpanded="-1"
              :getDataPath="getDataPath"
              :gridOptions="gridOptionsTemplate"
              :autoGroupColumnDef="autoGroupColumnDefTemplate"
              :frameworkComponents="frameworkComponents"
              :enableBrowserTooltips="true"
              @selection-changed="onGridSelectionChangedTemplate"
              @rowDoubleClicked="onGridDoubleClickTemplate"
              @sortChanged="onGridColumnsChangeTemplate"
              @columnResized="onGridColumnsChangeTemplate"
              @columnMoved="onGridColumnsChangeTemplate"
              @displayedColumnsChanged="onGridColumnsChangeTemplate"
              :overlayNoRowsTemplate="noRowsToShowTemplate"
              :sideBar="sideBar"
              :localeTextFunc="gridLocale"
              :pinnedBottomRowData="templatesTotalComp"
            >
            </ag-grid-vue>
          </pane>
          <pane min-size="40" max-size="80">
            <el-header class="header3" height="48"
              ><el-row type="flex" justify="start">
                <div style="padding-left:19px;">
                  <el-input
                    :placeholder="$t('Пошук')"
                    style="width:308px;"
                    prefix-icon="el-icon-search"
                    clearable
                    v-model="filterByName"
                    ref="inputFilterName2"
                    @keydown.esc.native="resetFilterName"
                  />
                </div> </el-row
            ></el-header>
            <div ref="flex_container_2">
              <splitpanes class="default-theme" horizontal @resize="onSplitResize">
                <pane min-size="20" max-size="80" ref="pane1">
                  <ag-grid-vue
                    v-show="activeTab == 'templates'"
                    ref="skuDictionary"
                    style="width: 100%; height: 100%;"
                    class="ag-theme-alpine"
                    :defaultColDef="defaultColDef"
                    :columnDefs="columns.skuDict"
                    :headerHeight="32"
                    :context="context"
                    :rowData="skuDictionary"
                    :modules="modules"
                    rowSelection="multiple"
                    :gridOptions="gridOptionsSKUDict"
                    :frameworkComponents="frameworkComponents"
                    :enableBrowserTooltips="true"
                    @selection-changed="onGridSelectionChangedSKUDict"
                    @rowDoubleClicked="onGridDoubleClickSKUDict"
                    @sortChanged="onGridColumnsChangeSKUDict"
                    @columnResized="onGridColumnsChangeSKUDict"
                    @columnMoved="onGridColumnsChangeSKUDict"
                    @displayedColumnsChanged="onGridColumnsChangeSKUDict"
                    :overlayNoRowsTemplate="noRowsToShowTemplate"
                    :sideBar="sideBar"
                    :localeTextFunc="gridLocale"
                  >
                  </ag-grid-vue>
                  <ag-grid-vue
                    v-show="activeTab == 'orgs'"
                    ref="orgsDictionary"
                    style="width: 100%; height: 100%;"
                    class="ag-theme-alpine"
                    treeData="true"
                    :getDataPath="getDataPath"
                    :autoGroupColumnDef="autoGroupColumnDefOrgs"
                    :defaultColDef="defaultColDef"
                    :columnDefs="columns.orgsDict"
                    :headerHeight="32"
                    :context="context"
                    :rowData="orgsDictionary"
                    :modules="modules"
                    rowSelection="multiple"
                    :gridOptions="gridOptionsOrgsDict"
                    :groupDefaultExpanded="-1"
                    :frameworkComponents="frameworkComponents"
                    :enableBrowserTooltips="true"
                    @selection-changed="onGridSelectionChangedOrgsDict"
                    @rowDoubleClicked="onGridDoubleClickOrg"
                    @sortChanged="onGridColumnsChangeOrgs"
                    @columnResized="onGridColumnsChangeOrgs"
                    @columnMoved="onGridColumnsChangeOrgs"
                    @displayedColumnsChanged="onGridColumnsChangeOrgs"
                    :overlayNoRowsTemplate="noRowsToShowTemplate"
                    :sideBar="sideBar"
                    :localeTextFunc="gridLocale"
                  >
                  </ag-grid-vue>
                </pane>
                <pane min-size="20" max-size="80" ref="pane2">
                  <el-header class="header2" height="32"
                    ><el-row type="flex" justify="start" align="center">
                      <TIButton
                        icon="plus_green"
                        @onClick="addNew"
                        :title="$t('Додати')"
                        :disabled="
                          !selected.template ||
                            (activeTab == 'templates' && (!selected.skuDict || selected.skuDict.length == 0)) ||
                            (activeTab == 'orgs' && (!selected.orgsDict || selected.orgsDict.length == 0))
                        "
                      />
                      <TIButton
                        icon="delete_red"
                        @onClick="deleteSelected"
                        :title="$t('Видалити')"
                        :disabled="
                          (activeTab == 'templates' && (!selected.sku || selected.sku.length == 0)) ||
                            (activeTab == 'orgs' && (!selected.orgs || selected.orgs.length == 0))
                        "
                      />
                      <TSeparator />
                      <TIButton
                        icon="apply_for_select_green"
                        @onClick="applyTemplateForSelected"
                        :label="$t('Встановити')"
                        v-if="activeTab == 'orgs'"
                        :disabled="!selected.orgs || selected.orgs.length == 0 || !selected.template"
                      />
                      <TSeparator v-if="activeTab == 'orgs'" />
                      <TIButton
                        icon="apply_for_select_red"
                        @onClick="clearTemplateForSelected"
                        :label="$t('Зкинути')"
                        v-if="activeTab == 'orgs'"
                        :disabled="!selected.orgs || selected.orgs.length == 0"
                      />
                      <TSeparator v-if="activeTab == 'orgs'" />
                    </el-row>
                  </el-header>
                  <ag-grid-vue
                    v-show="activeTab == 'templates'"
                    ref="templateSKU"
                    style="width: 100%; height: 100%;"
                    class="ag-theme-alpine"
                    :defaultColDef="defaultColDef"
                    :columnDefs="columns.sku"
                    :headerHeight="32"
                    :context="context"
                    :rowData="skuComp"
                    :modules="modules"
                    rowSelection="multiple"
                    :gridOptions="gridOptionsSKU"
                    :frameworkComponents="frameworkComponents"
                    :enableBrowserTooltips="true"
                    @selection-changed="onGridSelectionChangedSKU"
                    @rowDoubleClicked="onGridDoubleClickSKU"
                    @sortChanged="onGridColumnsChangeSKU"
                    @columnResized="onGridColumnsChangeSKU"
                    @columnMoved="onGridColumnsChangeSKU"
                    @displayedColumnsChanged="onGridColumnsChangeSKU"
                    :overlayNoRowsTemplate="noRowsToShowTemplate"
                    :sideBar="sideBar"
                    :localeTextFunc="gridLocale"
                    :pinnedBottomRowData="skuTotalComp"
                  >
                  </ag-grid-vue>
                  <ag-grid-vue
                    v-show="activeTab == 'orgs'"
                    ref="orgs"
                    style="width: 100%; height: 100%;"
                    class="ag-theme-alpine"
                    treeData="true"
                    :getDataPath="getDataPath"
                    :autoGroupColumnDef="autoGroupColumnDefOrgs"
                    :defaultColDef="defaultColDef"
                    :columnDefs="columns.orgs"
                    :headerHeight="32"
                    :context="context"
                    :rowData="orgsComp"
                    :modules="modules"
                    rowSelection="multiple"
                    :gridOptions="gridOptionsOrgs"
                    :groupDefaultExpanded="-1"
                    :frameworkComponents="frameworkComponents"
                    :enableBrowserTooltips="true"
                    @selection-changed="onGridSelectionChangedOrgs"
                    @rowDoubleClicked="onGridDoubleClickOrg"
                    @sortChanged="onGridColumnsChangeOrgs"
                    @columnResized="onGridColumnsChangeOrgs"
                    @columnMoved="onGridColumnsChangeOrgs"
                    @displayedColumnsChanged="onGridColumnsChangeOrgs"
                    :overlayNoRowsTemplate="noRowsToShowTemplate"
                    :sideBar="sideBar"
                    :localeTextFunc="gridLocale"
                    :pinnedBottomRowData="orgsTotalComp"
                  >
                  </ag-grid-vue>
                </pane>
              </splitpanes>
            </div>
          </pane>
        </splitpanes>
      </div>
    </el-main>
    <SelectTemplate @select-commit="onSelectTemplateCommit" bus-event="templates.select" />
    <EditRestrictTemplate @commit="restrictTemplatesCommitChanges" />
    <EditRestrictSKUGroup @commit="restrictGroupsCommitChanges" />
    <SKUInfo bus-event="templates.sku.forms.info" />
  </el-container>
</template>

<script>
import { AgGridVue } from "@ag-grid-community/vue";
import { AllModules } from "@ag-grid-enterprise/all-modules";
import Icon from "@/components/IconPack";
import TIButton from "@/components/ToolbarIconButton";
import TSeparator from "@/components/ToolbarSeparator";
import { bus } from "@/main";

import GridCellCheckbox from "@/components/GridCellCheckbox.js";
import GridCellWithIcons from "@/components/GridCellWithIcons.js";
// import GridTotalRow from "@/components/GridTotalRow.js";
import BreadCrumbs from "@/components/BreadCrumbs";
import UserProfileButton from "@/components/UserProfileButton";
import GridTotalBottomRow from "@/components/GridTotalBottomRow.js";
import { Splitpanes, Pane } from "splitpanes";
import "splitpanes/dist/splitpanes.css";
import { _ } from "vue-underscore";

function parseError(error) {
  if (typeof error == "string") {
    return error;
  } else if (Array.isArray(error)) {
    return error.join("; ");
  } else return error;
}

const Restricts = {
  name: "RestrictDetailsSet",
  components: {
    AgGridVue,
    TIButton,
    TSeparator,
    Icon,
    BreadCrumbs,
    UserProfileButton,
    Splitpanes,
    Pane,
    SKUInfo: () => import("@/components/forms/SKUInfo"),
    EditRestrictTemplate: () => import("@/components/forms/restricts/EditRestrictTemplate"),
    EditRestrictSKUGroup: () => import("@/components/forms/restricts/EditRestrictSKUGroup"),
    FindOrgInTree: () => import("@/components/forms/FindOrgInTree"),
    SelectTemplate: () => import("@/components/forms/restricts/SelectTemplate"),
  },
  beforeMount() {
    this.frameworkComponents = { GridCellWithIcons, GridCellCheckbox, GridTotalBottomRow };
    this.context = { componentParent: this };
  },
  mounted() {
    var state = this.$store.getters["main/userSettings"]("restrictDetailsSetTemplatesGridColumnState");
    if (state) this.gridOptionsTemplate.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsSetSKUDictGridColumnState");
    if (state) this.gridOptionsSKUDict.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsSetOrgsGridColumnState");
    if (state) this.gridOptionsOrgs.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsSetOrgsDictGridColumnState");
    if (state) this.gridOptionsOrgsChild.columnApi.setColumnState(state);
    var state = this.$store.getters["main/userSettings"]("restrictDetailsSetSKUGridColumnState");
    if (state) this.gridOptionsSKU.columnApi.setColumnState(state);

    this.activeTab = this.$store.getters["main/userSettings"]("restrictDetailsSetActiveTab") || "templates";
    this.observeHeight();
    this.checkGridSettingsToChanges(this, $tt("Шаблони"), "restrictDetailsSetTemplatesGridColumnState", this.columns["templates"]);
    this.checkGridSettingsToChanges(this, $tt("Товари"), "restrictDetailsSetSKUDictGridColumnState", this.columns["skuDict"]);
    this.checkGridSettingsToChanges(this, $tt("Обрані товари"), "restrictDetailsSetSKUGridColumnState", this.columns["sku"]);
    this.checkGridSettingsToChanges(this, $tt("Обрані cтруктури"), "restrictDetailsSetOrgsGridColumnState", this.columns["orgs"]);
    this.checkGridSettingsToChanges(this, $tt("Структури"), "restrictDetailsSetOrgsDictGridColumnState", this.columns["orgsDict"]);
    window.addEventListener("keydown", this.onKeyDownHandler);
  },
  beforeDestroy() {
    window.removeEventListener("keydown", this.onKeyDownHandler);
  },
  data() {
    var that = this;
    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n);
    }
    var digitalValueValidateRule = (value) => {
      if (value == undefined || value == "") {
        return true;
      } else if ((!isNumeric(value) && !isNumeric(value.split(",").join("."))) || value < 0) {
        this.$message.error($tt("Поле має бути невід'ємним числом"));
        return false;
      } else {
        return true;
      }
    };
    var checkEditFunction = function(params) {
      return !params.node.isRowPinned();
    };
    return {
      selected: {
        orgs: null,
        orgsDict: null,
        sku: null,
        skuDict: null,
        template: null,
        group: null,
      },
      getDataPath: (data) => {
        return data.path;
      },
      restrictDetailsSetLoading: false,
      activeTab: "rules",
      frameworkComponents: null,
      context: null,
      noRowsToShowTemplate: `<div><img src="${require("../assets/icons/no_data.svg")}"></div>`,
      titles: {
        titleAddItems: {
          templates: $tt("Створити новий шаблон"),
          orgs: $tt("Додати структуру"),
          sku: $tt("Додати товар"),
        },
        titleEditItems: {
          templates: $tt("Редагувати шаблон"),
          orgs: $tt("Редагувати"),
          sku: $tt("Редагувати"),
        },
        titleDelItems: {
          templates: $tt("Видалити шаблон"),
          orgs: $tt("Видалити"),
          sku: $tt("Видалити"),
        },
      },
      gridOptionsTemplate: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data._id;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
        stopEditingWhenGridLosesFocus: true,
      },
      gridOptionsSKU: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },

        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      gridOptionsSKUDict: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.drugsId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      gridOptionsOrgs: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
            {
              statusPanel: "GridTotalBottomRow",
              align: "left",
              items: [
                {
                  id: 1,
                  label: $tt("Організацій"),
                  value: function() {
                    return that.orgsComp ? that.orgsComp.filter((org) => org.okpo.length).length : 0;
                  },
                },
                {
                  id: 2,
                  label: $tt("Аптек"),
                  value: function() {
                    return that.orgsComp ? that.orgsComp.filter((org) => !org.okpo.length).length : 0;
                  },
                },
              ],
            },
          ],
        },
      },
      gridOptionsOrgsDict: {
        suppressCellSelection: true,
        getRowNodeId: function(data) {
          return data.axiomaId;
        },
        immutableData: true,
        rowHeight: 32,
        floatingFiltersHeight: 32,
        statusBar: {
          statusPanels: [
            {
              statusPanel: "agTotalAndFilteredRowCountComponent",
              align: "left",
            },
          ],
        },
      },
      windowHeight: 0,
      skuPaneHeight: 0,
      resizeObserver: null,
      resizeskuPaneObserver: null,
      modules: AllModules,
      filterByName: "",
      sideBar: {
        toolPanels: ["columns", "filters"],
        position: "left",
        hiddenByDefault: false,
      },
      bottomTotalRow: {
        orgs: null,
      },
      defaultColDef: {
        suppressNavigable: true,
        cellClass: "no-border",
        resizable: true,
        sortable: true,
        filter: true,
        tooltipValueGetter: function(params) {
          return params.value;
        },
        floatingFilter: this.$store.getters["main/userSettings"]("userSettings").floatingFilter == "true",
        suppressMenu: false,
        filter: "agTextColumnFilter",
        floatingFilterComponentParams: { suppressFilterButton: true },
      },
      autoGroupColumnDef: {
        headerName: $tt("Назва"),
        flex: 2,
        field: "name",
        cellRendererParams: {
          suppressCount: true,
        },
        comparator: function(valueA, valueB) {
          return valueA > valueB ? 1 : -1;
        },
        sort: "asc",
      },
      autoGroupColumnDefTemplate: {
        headerName: $tt("Назва шаблона"),
        flex: 2,
        field: "name",
        cellRendererParams: {
          suppressCount: true,
        },
        comparator: function(valueA, valueB) {
          return valueA > valueB ? 1 : -1;
        },
        sort: "asc",
      },
      autoGroupColumnDefOrgs: {
        headerName: $tt("Назва"),
        flex: 2,
        field: "name",
        cellRendererParams: {
          suppressCount: true,
        },
        headerCheckboxSelection: true,
        checkboxSelection: true,
        headerCheckboxSelectionFilteredOnly: false,
        comparator: function(valueA, valueB, nodeA, nodeB, isInverted) {
          if (!nodeA || !nodeA.data) return 0;
          if (!nodeB || !nodeB.data) return 0;
          if (nodeA.data.okpo != "" && nodeB.data.okpo == "") return -1;
          if (nodeA.data.okpo == "" && nodeB.data.okpo != "") return 1;
          return valueA > valueB ? 1 : -1;
        },
        sort: "asc",
      },
      columns: {
        templates: [
          {
            pinned: "left",
            cellRenderer: "GridCellWithIcons",
            filter: false,
            cellIcons: [
              {
                icon: "edit",
                iconClass: "icon-action",
                type: "fas el-icon-fa-",
                color: "green",
                title: $tt("Редагувати"),
                onClick: (params) => {
                  this.editSelectedTemplate(null, params.data);
                },
              },
              {
                icon: "trash",
                iconClass: "icon-action",
                type: "fas el-icon-fa-",
                color: "red",
                title: $tt("Видалити"),
                onClick: (params) => {
                  if (params.data.parent_id) this.deleteSelectedGroup(null, params.data);
                  else this.deleteSelectedTemplate(null, params.data);
                },
              },
              {
                icon: "paperclip",
                type: "fas el-icon-fa-",
                color: "#800",
                iconClass: "icon-action",
                title: function(params) {
                  return $tt("Додаток") + ": " + params.data.attachFile.srcName;
                },
                onClick: (params) => {
                  this.downloadFile(params.data);
                },
                show: function(params) {
                  return params.data.attachFile;
                },
              },
            ],
            width: 70,
          },
          {
            headerName: $tt("Бонус"),
            field: "bonus",
            flex: 1,
            type: "rightAligned",
          },
          {
            headerName: $tt("Товарів"),
            field: "_skuCount",
            flex: 1,
            type: "rightAligned",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        orgs: [
          {
            headerName: $tt("Адреса"),
            field: "address",
            width: "200px",
          },
          {
            headerName: "AxiomaID",
            field: "axiomaId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("ЄДРПОУ"),
            field: "okpo",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Шаблон"),
            field: "status",
            width: "175px",
            cellRenderer: "GridCellWithIcons",
            cellIcons: [
              {
                icon: "edit",
                iconClass: "icon-action",
                type: "fas el-icon-fa-",
                color: "green",
                title: $tt("Редагувати"),
                onClick: (params) => {
                  this.editSelectedOrg(null, params.data);
                },
              },
            ],
            valueGetter: (params) => {
              if (params.data && params.data.templateId) {
                var template = this.$store.getters["templates/getTemplateById"](params.data.templateId);
                return template ? template.name : params.data.templateId;
              }
              return "";
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Примітка"),
            field: "notes",
            flex: 1,
          },
          {
            headerName: $tt("Статус"),
            field: "status",
            width: "75px",
            valueGetter: (data) => {
              return data && data.data && data.data.status ? $tt("Активний") : "-";
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Категорія"),
            field: "srcCategory",
            width: "50px",
            filter: "agSetColumnFilter",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        orgsDict: [
          {
            headerName: $tt("Адреса"),
            field: "address",
            width: "200px",
          },
          {
            headerName: "AxiomaID",
            field: "axiomaId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("ЄДРПОУ"),
            field: "okpo",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Організація"),
            field: "orgName",
            width: "160px",
          },
          {
            headerName: $tt("Примітка"),
            field: "notes",
            flex: 1,
          },
          {
            headerName: $tt("Статус"),
            field: "status",
            width: "75px",
            valueGetter: (data) => {
              return data && data.data && data.data.status ? $tt("Активний") : "-";
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Шаблон"),
            field: "template",
            width: "175px",
            valueGetter: (params) => {
              var orgs = this.$store.getters["restricts/orgs"](null);
              var org = orgs.find((o) => o.axiomaId == params.data.axiomaId);
              var templateId = org ? org.templateId : null;
              if (templateId) {
                var template = this.$store.getters["templates/getTemplateById"](templateId);
                return template ? template.name : templateId;
              }
              return "";
            },
            filter: "agSetColumnFilter",
          },
          {
            headerName: $tt("Категорія"),
            field: "srcCategory",
            width: "50px",
            filter: "agSetColumnFilter",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        skuDict: [
          {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRenderer: "GridCellWithIcons",
            cellIcons: [
              {
                icon: "info-circle",
                type: "fas el-icon-fa-",
                color: "blue",
                iconClass: "icon-action",
                title: $tt("Інформація"),
                onClick: (params) => {
                  this.showInfo(null, params.data);
                },
              },
            ],
            headerName: $tt("Назва"),
            field: "sku",
            flex: 2,
          },
          {
            headerName: "MorionID",
            field: "drugsId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Виробник"),
            field: "maker",
            flex: 1,
          },
          {
            headerName: $tt("Маркетуюча організація"),
            field: "marketOrg",
            flex: 1,
          },
          {
            headerName: $tt("Бренд"),
            field: "brand",
            flex: 1,
          },
          {
            headerName: $tt("Рег. дата початок"),
            field: "regDateBegin",
            width: "200px",
            filter: "agDateColumnFilter",
          },
          {
            headerName: $tt("Рег. дата завершення"),
            field: "regDateEnd",
            width: "200px",
            filter: "agDateColumnFilter",
          },
          {
            headerName: $tt("CIP Ціна"),
            field: "cip",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("CIP Ціна, Грн"),
            field: "cipUAH",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Форма"),
            field: "drugForm",
            flex: 1,
          },
          {
            headerName: $tt("Номер"),
            field: "drugFormNumber",
            width: "100px",
            type: "rightAligned",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
        sku: [
          {
            checkboxSelection: true,
            headerCheckboxSelection: true,
            headerCheckboxSelectionFilteredOnly: true,
            cellRenderer: "GridCellWithIcons",
            cellIcons: [
              {
                icon: "edit",
                iconClass: "icon-action",
                type: "fas el-icon-fa-",
                color: "green",
                title: $tt("Редагувати"),
                onClick: (params) => {
                  this.editSelectedSKU(null, params.data);
                },
              },
              {
                icon: "trash",
                iconClass: "icon-action",
                type: "fas el-icon-fa-",
                color: "red",
                title: $tt("Видалити"),
                onClick: (params) => {
                  this.deleteSelectedSKU(null, params.data);
                },
              },
              {
                icon: "info-circle",
                type: "fas el-icon-fa-",
                color: "blue",
                iconClass: "icon-action",
                title: $tt("Інформація"),
                onClick: (params) => {
                  this.showInfo(null, params.data);
                },
              },
              {
                icon: "paperclip",
                type: "fas el-icon-fa-",
                color: "#800",
                iconClass: "icon-action",
                title: function(params) {
                  return $tt("Додаток") + ": " + params.data.attachFile.srcName;
                },
                onClick: (params) => {
                  this.downloadSKUFile(params.data);
                },
                show: function(params) {
                  return params.data.attachFile;
                },
              },
            ],
            headerName: $tt("Назва"),
            field: "sku",
            flex: 2,
          },
          {
            headerName: "MorionID",
            field: "drugsId",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Група"),
            field: "groupId",
            width: "120px",
            valueGetter(params) {
              if (params.data) {
                var group = that.$store.getters["templates/getGroupById"](params.data.groupId);
                if (group) return group.name;
              }
              return "";
            },
          },
          {
            headerName: $tt("Виробник"),
            field: "maker",
            flex: 1,
          },
          {
            headerName: $tt("Маркетуюча організація"),
            field: "marketOrg",
            flex: 1,
          },
          {
            headerName: $tt("Бренд"),
            field: "brand",
            flex: 1,
          },
          {
            headerName: $tt("Рег. дата початок"),
            field: "regDateBegin",
            width: "200px",
            filter: "agDateColumnFilter",
          },
          {
            headerName: $tt("Рег. дата завершення"),
            field: "regDateEnd",
            width: "200px",
            filter: "agDateColumnFilter",
          },
          {
            headerName: $tt("CIP Ціна"),
            field: "cip",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("CIP Ціна, Грн"),
            field: "cipUAH",
            width: "200px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Форма"),
            field: "drugForm",
            flex: 1,
          },
          {
            headerName: $tt("Номер"),
            field: "drugFormNumber",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Фейсів"),
            field: "faceCount",
            width: "100px",
            type: "rightAligned",
          },
          {
            headerName: $tt("Фото"),
            field: "photoCount",
            width: "100px",
            type: "rightAligned",
          },
        ].map((column) => _.extend(column, { headerTooltip: column.headerName })),
      },
    };
  },
  computed: {
    skuGridStyle() {
      return "width: 100%; height: 100%;";
    },
    restrict() {
      return this.$store.getters["restricts/restrict"]();
    },
    templatesTree() {
      return this.$store.getters["templates/templatesTree"]();
    },
    template() {
      return this.$store.getters["templates/template"]();
    },
    groupsComp() {
      return this.template ? this.template.groups : [];
    },
    group() {
      return this.$store.getters["templates/group"]();
    },
    skuDictionary() {
      var selectedIds = [];
      if (this.template) selectedIds = this.template.sku.map((s) => s.drugsId);
      var filterByName = this.filterByName ? this.filterByName.toLowerCase() : null;
      var contract = this.$store.getters["contracts/contract"]();
      if (contract && contract.sku) {
        return contract.sku
          .filter((sku) => selectedIds.indexOf(sku.drugsId) == -1)
          .filter((sku) => {
            return !filterByName || sku.sku.toLowerCase().search(filterByName) >= 0;
          });
      } else return [];
    },
    skuComp() {
      return this.$store.getters["templates/sku"](this.selected.group);
    },
    orgsComp() {
      return this.$store.getters["restricts/orgs"](this.selected.template ? this.selected.template._id : null);
      //return this.$store.getters["restricts/orgs"]();
    },
    orgsFullComp() {
      var orgs = this.$store.getters["restricts/orgs"](null);
      if (orgs) {
        var orgsWithTemplate = {};
        orgs.forEach((o) => {
          if (o.templateId) orgsWithTemplate[o.axiomaId] = o.templateId;
        });
        return orgsWithTemplate;
      } else return {};
    },
    orgsDictionary() {
      return this.$store.getters["contracts/contractStructuresWithParents"](this.filterByName);
    },
    orgsWithTemplateComp() {
      var orgs = this.$store.getters["restricts/orgs"](this.selected.template ? this.selected.template._id : null);
      if (orgs) {
        var orgsWithTemplate = {};
        orgs.forEach((o) => {
          if (o.templateId) orgsWithTemplate[o.axiomaId] = o.templateId;
        });
        return orgsWithTemplate;
      } else return {};
      //return this.$store.getters["restricts/orgs"]();
    },
    orgsTotalComp() {
      return 0;
    },
    templatesTotalComp() {
      return 0;
    },
    skuTotalComp() {
      return 0;
    },
    selectOptions() {
      return this.$store.getters["main/selectionOptions"];
    },
  },
  watch: {
    activeTab(value) {
      this.$store.dispatch("main/updateUserSettings", { restrictDetailsSetActiveTab: value });
    },
    windowHeight(value) {
      this.updateTabSizes(value);
    },
  },
  methods: {
    updateTabSizes(value) {
      var containerHeight = value - 87;
      this.$refs.flex_container.style.height = `${containerHeight}px`;
      this.$refs.flex_container_2.style.height = `${containerHeight}px`;
      this.$refs.templatesTree.$el.style.height = `${containerHeight - 47}px`;
      this.onSplitResize();
    },
    reload() {
      this.$message("!");
    },
    downloadFile(template) {
      if (template) {
        this.$store.dispatch("main/setGlobalLoading", $tt("Завантаження файлу..."));
        this.$store
          .dispatch("templates/downloadAttachedFile", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            templateId: template._id,
            fileId: template.attachFile.id,
            fileName: template.attachFile.srcName,
          })
          .finally(() => {
            this.$store.dispatch("main/setGlobalLoading");
          })
          .then(() => {})
          .catch((err) => {
            globalErrorMessage($tt("Помилка"), err);
          });
      }
    },
    downloadSKUFile(sku) {
      if (sku) {
        this.$store.dispatch("main/setGlobalLoading", $tt("Завантаження файлу..."));
        this.$store
          .dispatch("templates/downloadAttachedSKUFile", {
            contractId: this.$store.getters["contracts/contract"]()._id,
            templateId: this.selected.template._id,
            drugsId: sku.drugsId,
            fileId: sku.attachFile.id,
            fileName: sku.attachFile.srcName,
          })
          .finally(() => {
            this.$store.dispatch("main/setGlobalLoading");
          })
          .then(() => {})
          .catch((err) => {
            globalErrorMessage($tt("Помилка"), err);
          });
      }
    },
    onSplitResize(event) {
      var height2 = this.$refs.pane2.$el.clientHeight - 80;
      if (this.activeTab == "templates") this.$refs.templateSKU.$el.style = `width:100%; height:${height2}px`;
      if (this.activeTab == "orgs") this.$refs.orgs.$el.style = `width:100%; height:${height2}px`;
    },
    onTabChange() {
      this.updateTabSizes(this.windowHeight);
    },
    observeHeight() {
      var that = this;
      if (!this.resizeObserver) {
        this.resizeObserver = new ResizeObserver(function() {
          that.windowHeight = window.innerHeight;
        });
        this.resizeObserver.observe(document.body);
      }
    },
    unobserveHeight() {
      this.resizeObserver && this.resizeObserver.unobserve(document.body);
      this.resizeObserver = null;
      this.windowHeight = 0;
    },
    resetFilterName(event) {
      this.$refs.inputFilterName.clear();
    },
    restrictTemplatesCommitChanges(data) {
      if (data.operation == "create") {
        var contractId = this.$store.getters["contracts/contract"]()._id;
        var loading = this.$loading({ target: "#EditRestrictTemplateDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("templates/create", { contractId, data: data.model })
          .finally(() => {
            loading && loading.close();
          })
          .then((newData) => {
            bus.$emit("forms.templates.edit.hide");
            this.$message.success($tt("Шаблон створено!"));
            var node = this.gridOptionsTemplate.api.getRowNode(newData.data._id);
            if (node) node.setSelected(true);
          })
          .catch((err) => {
            globalErrorMessage("Помилка створення!", err);
          });
      } else if (data.operation == "update") {
        var loading = this.$loading({ target: "#EditRestrictTemplateDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("templates/update", { contractId: this.$store.getters["contracts/contract"]()._id, templateId: data.model._id, data: data.model })
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("forms.templates.edit.hide");
            this.$message.success("Умову оновлено!");
            var rowNode = this.gridOptionsTemplate.api.getRowNode(data.model._id);
            rowNode.setData(data.model);
            this.gridOptionsTemplate.api.redrawRows({ rowNodes: [rowNode] });
          })
          .catch((err) => {
            globalErrorMessage("Помилка збереження!", err);
          });
      }
    },
    restrictGroupsCommitChanges(data) {
      var that = this;
      if (data.operation == "create") {
        var contractId = this.$store.getters["contracts/contract"]()._id;
        var templateId = this.$store.getters["templates/template"]()._id;
        var existIds = this.template.sku.map((item) => item.drugsId);
        var newSKU = this.selected.skuDict.filter((item) => existIds.indexOf(item.drugsId) == -1);
        if (!newSKU || newSKU.length == 0) {
          this.$message($tt("Не знайдено нових товарів для додавання до шаблону!"));
          return;
        }
        // no Group
        if (data.model.group == "0") {
          newSKU.forEach((sku) => {
            sku.groupId = "0";
            sku.faceCount = data.model.faceCount;
            sku.photoCount = data.model.faceCount;
            sku.attachFile = data.model.attachFile;
          });
          var loading = this.$loading({ target: "#EditRestrictSKUGroupDialog>div", text: $tt("Будь ласка, зачекайте...") });
          this.$store
            .dispatch("templates/insertSKU", { contractId, templateId, sku: newSKU })
            .finally(() => {
              loading && loading.close();
            })
            .then((data) => {
              bus.$emit("forms.templates.groups.edit.hide");
              this.$message.success($tt("Товари збережено в шаблоні!"));
              this.gridOptionsTemplate.api.refreshCells();
            });
        }
        // New Group
        else if (data.model.group == "-1") {
          var loading = this.$loading({ target: "#EditRestrictSKUGroupDialog>div", text: $tt("Будь ласка, зачекайте...") });
          this.$store
            .dispatch("templates/insertGroup", { contractId, templateId, data: data.model })
            .then((newGroup) => {
              this.$message.success($tt("Групу збережено в шаблоні!"));
              newSKU.forEach((sku) => {
                sku.groupId = newGroup.data._id;
                sku.faceCount = data.model.faceCount;
                sku.photoCount = data.model.photoCount;
                sku.attachFile = data.model.attachFile;
              });
              this.$store
                .dispatch("templates/insertSKU", { contractId, templateId, sku: newSKU })
                .finally(() => {
                  loading && loading.close();
                })
                .then((data) => {
                  bus.$emit("forms.templates.groups.edit.hide");
                  this.$message.success($tt("Товари збережено в шаблоні!"));
                  this.gridOptionsTemplate.api.refreshCells();
                })
                .catch((err) => {
                  globalErrorMessage("Помилка збереження товарів!", err);
                });
            })
            .catch(() => {
              globalErrorMessage("Помилка збереження групи!", err);
            });
        }
        // Insert to Exists Group
        else {
          var group = this.$store.getters["templates/getGroupById"](data.model.group);
          if (group) {
            newSKU.forEach((sku) => {
              sku.groupId = group._id;
              sku.faceCount = group.faceCount;
              sku.photoCount = data.model.photoCount;
              sku.attachFile = data.model.attachFile;
            });
            this.$store
              .dispatch("templates/insertSKU", { contractId, templateId, sku: newSKU })
              .finally(() => {
                loading && loading.close();
              })
              .then((data) => {
                bus.$emit("forms.templates.groups.edit.hide");
                this.$message.success($tt("Товари збережено в шаблоні!"));
                this.gridOptionsTemplate.api.refreshCells();
              })
              .catch((err) => {
                globalErrorMessage("Помилка збереження товарів!", err);
              });
          } else {
            this.$message($tt("Не знайдено вказану групу в шаблні!"));
            return;
          }
        }
      } else if (data.operation == "update") {
        var contractId = this.$store.getters["contracts/contract"]()._id;
        var loading = this.$loading({ target: "#EditRestrictSKUGroupDialog>div", text: $tt("Будь ласка, зачекайте...") });
        this.$store
          .dispatch("templates/updateGroup", {
            contractId,
            templateId: data.model.parent_id,
            groupId: data.model._id,
            data: data.model,
          })
          .finally(() => {
            loading && loading.close();
          })
          .then(() => {
            bus.$emit("forms.templates.groups.edit.hide");
            that.$message.success("Групу оновлено!");
            that.gridOptionsTemplate.api.refreshCells();
            if (that.selected.group) {
              var group = that.$store.getters["templates/getGroupById"](that.selected.group);
              group = data.model;
              var groupTplIdx = that.selected.template.groups.findIndex((g) => g._id == that.selected.group);
              if (groupTplIdx >= 0) {
                that.selected.template.groups[groupTplIdx] = group;
              }
              that.gridOptionsSKU.api.refreshCells();
            }
          })
          .catch((err) => {
            globalErrorMessage("Помилка збереження!", err);
          });
      } else if (data.operation == "updateSKU") {
        var sku = this.template.sku.find((s) => s.drugsId == data.model.forSKU);
        if (sku) {
          var contractId = this.$store.getters["contracts/contract"]()._id;
          var templateId = this.$store.getters["templates/template"]()._id;
          sku.groupId = data.model.group;
          sku.faceCount = data.model.faceCount;
          sku.photoCount = data.model.photoCount;
          sku.attachFile = data.model.attachFile;
          var loading = this.$loading({ target: "#EditRestrictSKUGroupDialog>div", text: $tt("Будь ласка, зачекайте...") });
          // To New Group
          if (data.model.group == "-1") {
            this.$store
              .dispatch("templates/insertGroup", { contractId, templateId, data: data.model })
              .then((newGroup) => {
                this.$message.success($tt("Групу збережено в шаблоні!"));
                sku.groupId = newGroup.data._id;
                this.$store
                  .dispatch("templates/updateSKU", { contractId, templateId, data: sku })
                  .finally(() => {
                    loading && loading.close();
                  })
                  .then((data) => {
                    bus.$emit("forms.templates.groups.edit.hide");
                    this.$message.success($tt("Товар оновлено!"));
                    this.gridOptionsTemplate.api.refreshCells();
                  })
                  .catch((err) => {
                    globalErrorMessage("Помилка збереження товарів!", err);
                  });
              })
              .catch((err) => {
                globalErrorMessage("Помилка збереження групи!", err);
              });
          }
          // Simply Update or move to Group
          else {
            this.$store
              .dispatch("templates/updateSKU", {
                contractId: this.$store.getters["contracts/contract"]()._id,
                templateId: this.template._id,
                data: sku,
              })
              .finally(() => {
                loading && loading.close();
              })
              .then(() => {
                bus.$emit("forms.templates.groups.edit.hide");
                that.$message.success("Товар оновлено!");
                that.gridOptionsTemplate.api.refreshCells();
                that.gridOptionsSKU.api.refreshCells();
              })
              .catch((err) => {
                globalErrorMessage("Помилка збереження!", err);
              });
          }
        }
      }
    },
    showInfo(event, data) {
      if (data) {
        bus.$emit("templates.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: data.drugsId },
        });
      } else if (this.selected.sku && this.selected.sku.length) {
        bus.$emit("templates.sku.forms.info.show", {
          title: $tt("Інформація про товар"),
          initModel: { drugsId: this.selected.sku[0].drugsId },
        });
      }
    },
    editSelectedOrg(event, data) {
      bus.$emit("templates.select.show", {
        title: $tt("Вибір шаблону"),
        initModel: {
          templateId: data ? data.templateId : this.selected.orgs && this.selected.orgs.length == 1 ? this.selected.orgs[0].templateId : null,
          axiomaId: data ? data.axiomaId : this.selected.orgs && this.selected.orgs.length == 1 ? this.selected.orgs[0].axiomaId : null,
        },
      });
    },
    onSelectTemplateCommit(data) {
      var that = this;
      if (data) {
        var loading = this.$loading({ target: "#SelectTemplateDialog>div", text: $tt("Будь ласка, зачекайте...") });
        var org = this.orgsComp.find((org) => org.axiomaId == data.axiomaId);
        if (org) {
          org.templateId = data.template ? data.template._id : null;
          this.$store
            .dispatch("restricts/updateOrg", {
              contractId: this.$store.getters["contracts/contract"]()._id,
              restrictId: this.$store.getters["restricts/restrict"]()._id,
              axiomaId: org.axiomaId,
              data: org,
            })
            .finally(() => {
              loading && loading.close();
            })
            .then(() => {
              bus.$emit("templates.select.hide");
              this.$message.success($tt("Шаблон встановлено!"));
              this.gridOptionsOrgs.api.refreshCells();
              var rowNode = this.gridOptionsOrgsDict.api.getRowNode(org.axiomaId);
              this.gridOptionsOrgsDict.api.redrawRows({ rowNodes: [rowNode] });
            });
        }
      }
    },
    editIconClick(item) {
      this.$message("!");
    },
    onGridDoubleClickTemplate() {
      this.editSelectedTemplate(null);
    },
    onGridDoubleClickGroup() {
      this.editSelectedGroup(null);
    },
    onGridDoubleClickSKU(event) {
      //this.editSelected(event, event.data);
    },
    onGridDoubleClickOrg(event) {
      if (!event.node.isRowPinned()) {
        event.api.stopEditing();
        //this.editSelected(event, event.data);
      }
    },
    onGridDoubleClick(event) {
      if (!event.node.isRowPinned()) {
        //this.editSelected(event, event.data);
      }
    },

    wait(timeout) {
      return new Promise((resolve) => {
        setTimeout(resolve, timeout);
      });
    },
    onGridSelectionChangedTemplate() {
      var rows = this.gridOptionsTemplate.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.template = rows[0];
      else this.selected.template = null;
      if (this.selected.template && this.selected.template.parent_id) {
        this.selected.group = this.selected.template._id;
        this.selected.template = this.$store.getters["templates/getTemplateById"](this.selected.template.parent_id);
      } else {
        this.selected.group = null;
      }
      if (this.selected.template) {
        this.$store.dispatch("templates/loadOne", { id: this.$store.getters["contracts/contract"]()._id, id2: this.selected.template._id });
      } else {
        this.$store.dispatch("templates/clearOne");
      }
    },
    onGridSelectionChangedOrgs() {
      var that = this;
      var rows = this.gridOptionsOrgs.api.getSelectedRows();
      var addIds = [];
      var delIds = [];
      if (!this.selected.orgs) {
        addIds = rows.map((item) => item.axiomaId);
      } else {
        var oldSelectedIds = this.selected.orgs.map((item) => item.axiomaId);
        var newSelectedIds = rows.map((item) => item.axiomaId);
        addIds = newSelectedIds.filter((x) => !oldSelectedIds.includes(x));
        delIds = oldSelectedIds.filter((x) => !newSelectedIds.includes(x));
      }
      if (addIds.length > 0) {
        this.orgsComp.forEach((item) => {
          var intersect = item.path.filter((value) => addIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptionsOrgs.api.getRowNode(item.axiomaId);
            if (node && !node.selected) node.setSelected(true);
          }
        });
      } else if (delIds.length > 0) {
        this.orgsComp.forEach((item) => {
          var intersect = item.path.filter((value) => delIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptionsOrgs.api.getRowNode(item.axiomaId);
            if (node && node.selected) node.setSelected(false);
          }
        });
      }
      if (!rows || rows.length == 0) this.selected.orgs = null;
      else this.selected.orgs = rows;
    },
    onGridSelectionChangedOrgsDict() {
      var that = this;
      var rows = this.gridOptionsOrgsDict.api.getSelectedRows();
      var addIds = [];
      var delIds = [];
      if (!this.selected.orgsDict) {
        addIds = rows.map((item) => item.axiomaId);
      } else {
        var oldSelectedIds = this.selected.orgsDict.map((item) => item.axiomaId);
        var newSelectedIds = rows.map((item) => item.axiomaId);
        addIds = newSelectedIds.filter((x) => !oldSelectedIds.includes(x));
        delIds = oldSelectedIds.filter((x) => !newSelectedIds.includes(x));
      }
      if (addIds.length > 0) {
        this.orgsDictionary.forEach((item) => {
          var intersect = item.path.filter((value) => addIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptionsOrgsDict.api.getRowNode(item.axiomaId);
            if (node && !node.selected) node.setSelected(true);
          }
        });
      } else if (delIds.length > 0) {
        this.orgsDictionary.forEach((item) => {
          var intersect = item.path.filter((value) => delIds.includes(value[0]));
          if (intersect.length > 0) {
            var node = that.gridOptionsOrgsDict.api.getRowNode(item.axiomaId);
            if (node && node.selected) node.setSelected(false);
          }
        });
      }
      if (!rows || rows.length == 0) this.selected.orgsDict = null;
      else this.selected.orgsDict = rows;
    },
    onGridSelectionChangedSKU() {
      var rows = this.gridOptionsSKU.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.sku = rows;
      else this.selected.sku = null;
    },
    onGridSelectionChangedSKUDict() {
      var rows = this.gridOptionsSKUDict.api.getSelectedRows();
      if (rows && rows.length > 0) this.selected.skuDict = rows;
      else this.selected.skuDict = null;
    },
    onGridDoubleClickSKUDict() {
      if (this.selected.template) {
        this.addNewSKU();
      } else {
        this.$message.error($tt("Будь ласка, оберіть шаблон!"));
      }
    },
    getRestrictRuleById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictRuleById"](id);
      } else return null;
    },
    getRestrictOrgById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictOrgById"](id);
      } else return null;
    },
    getRestrictSKUById(id) {
      if (id) {
        return this.$store.getters["restricts/getRestrictSKUById"](id);
      } else return null;
    },
    getTemplateById(id) {
      if (id) {
        return this.$store.getters["templates/getTemplateById"](id);
      } else return null;
    },
    getGroupById(id) {
      if (id) {
        return this.$store.getters["templates/getGroupById"](id);
      } else return null;
    },
    createNewTemplate() {
      bus.$emit("forms.templates.edit.show", {
        title: $tt("Створення шаблону"),
        operation: "create",
        initModel: { bonusMeasure: this.restrict.bonusMeasure },
      });
    },
    addNew() {
      if (this.activeTab == "templates") {
        this.addNewSKU();
      } else {
        this.addNewOrg();
      }
    },
    addNewSKU() {
      if (this.selected.skuDict && this.selected.skuDict.length > 0) {
        bus.$emit("forms.templates.groups.edit.show", {
          title: $tt("Додавання товарів"),
          operation: "create",
          initModel: { group: this.selected.group ? this.selected.group : "0", templateId: this.$store.getters["templates/template"]()._id },
        });
      }
    },
    addNewOrg() {
      if (this.selected.orgsDict && this.selected.orgsDict.length > 0) {
        var existIds = this.restrict.orgs.map((item) => item.axiomaId);
        var newOrgs = this.selected.orgsDict.filter((item) => existIds.indexOf(item.axiomaId) == -1);
        var orgsForUpdate = this.selected.orgsDict.filter((item) => existIds.indexOf(item.axiomaId) >= 0);
        if (newOrgs.length) {
          this.$confirm($tt("Для додавання обрано {count} структур. Додати обрані структури?", { count: newOrgs.length }), $tt("Увага"), {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          })
            .then(() => {
              newOrgs.forEach((org) => {
                org.templateId = this.template._id;
              });
              var parentOrgs = this.$store.getters["contracts/getParentOrgs"](newOrgs.map((o) => o.axiomaId));
              newOrgs = newOrgs.concat(parentOrgs);
              this.$store
                .dispatch("restricts/insertOrg", {
                  contractId: this.$store.getters["contracts/contract"]()._id,
                  restrictId: this.$store.getters["restricts/restrict"]()._id,
                  data: newOrgs,
                })
                .then((data) => {
                  if (newOrgs && newOrgs.length) {
                    this.$message.success($tt("Додано {count} структур!", { count: newOrgs.length }));
                    var rowNodes = newOrgs.map((org) => this.gridOptionsOrgsDict.api.getRowNode(org.axiomaId));
                    this.gridOptionsOrgsDict.api.redrawRows({ rowNodes: rowNodes });
                  }
                })
                .catch(() => {
                  globalErrorMessage($tt("Помилка збереження!"), err);
                });
            })
            .catch((err) => {});
        }
        if (orgsForUpdate.length) {
          this.$confirm($tt("Для оновлення обрано {count} структур. Додати обрані структури?", { count: orgsForUpdate.length }), $tt("Увага"), {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          })
            .then(() => {
              this.$store.dispatch("main/setGlobalLoading", "Оновлення структур...");
              var count = 1;
              orgsForUpdate
                .reduce((prev, org) => {
                  return prev.then(() => {
                    this.$store.dispatch("main/setGlobalLoading", `Оновлення структур...${count++} із ${orgsForUpdate.length}`);
                    this.$store
                      .dispatch("restricts/updateOrg", {
                        contractId: this.$store.getters["contracts/contract"]()._id,
                        restrictId: this.$store.getters["restricts/restrict"]()._id,
                        axiomaId: org.axiomaId,
                        data: _.extend(org, { templateId: this.template ? this.template._id : null }),
                      })
                      .catch((err) => {
                        globalErrorMessage($tt("Помилка оновлення!"), err);
                        this.$store.dispatch("main/setGlobalLoading");
                      });
                  });
                }, Promise.resolve())
                .then(() => {
                  this.$message.success($tt("Структури  оновлено!"));
                  this.$store.dispatch("main/setGlobalLoading");
                  this.gridOptionsOrgs.api.refreshCells();
                  this.gridOptionsOrgsDict.api.refreshCells();
                });
            })
            .catch((err) => {});
        }
      }
    },
    editSelectedTemplate(event, data) {
      if (data && data.parent_id) {
        this.editSelectedGroup(event, data ? data._id : this.selected.group, data.parent_id);
      } else if (data) {
        bus.$emit("forms.templates.edit.show", {
          title: $tt("Редагування шаблона"),
          operation: "update",
          initModel: data ? this.getTemplateById(data._id) : this.selected.template ? this.selected.template : {},
        });
      } else {
        if (this.selected.group) {
          this.editSelectedGroup(event, data ? data._id : this.selected.group);
        } else {
          bus.$emit("forms.templates.edit.show", {
            title: $tt("Редагування шаблона"),
            operation: "update",
            initModel: data ? this.getTemplateById(data._id) : this.selected.template ? this.selected.template : {},
          });
        }
      }
    },
    editSelectedGroup(event, data, parent_id) {
      bus.$emit("forms.templates.groups.edit.show", {
        title: $tt("Редагування групи"),
        operation: "update",
        initModel: _.extend(
          data
            ? this.$store.getters["templates/getGroupById"](data)
            : this.selected.group
            ? this.$store.getters["templates/getGroupById"](this.selected.group)
            : {},
          { parent_id: parent_id }
        ),
      });
    },
    editSelectedSKU(event, data) {
      var skuEdit = {};
      var templateId = this.selected.template._id;
      if (!data && this.selected && this.selected.sku && this.selected.sku.length) {
        data = this.selected.sku[0];
      }
      if ((data && data.groupId && data.groupId == "0") || (data && data.groupId == undefined)) {
        // no group
        skuEdit.group = "0";
        skuEdit.name = "";
        skuEdit.attachFile = data.attachFile;
        skuEdit.faceCount = data.faceCount;
        skuEdit.photoCount = data.photoCount;
        skuEdit.forSKU = data.drugsId;
        skuEdit.templateId = templateId;
      } else if (data && data.groupId && data.groupId != "0") {
        // in group
        var group = this.$store.getters["templates/getGroupById"](data.groupId);
        skuEdit.group = group._id;
        skuEdit.name = group.name;
        /*
        skuEdit.attachFile = group.attachFile;
        skuEdit.faceCount = group.faceCount;
        skuEdit.photoCount = group.photoCount;*/
        // Allow edit sku in group
        skuEdit.attachFile = data.attachFile;
        skuEdit.faceCount = data.faceCount;
        skuEdit.photoCount = data.photoCount;
        skuEdit.forSKU = data.drugsId;
        skuEdit.templateId = templateId;
      } else return;
      bus.$emit("forms.templates.groups.edit.show", {
        title: $tt("Редагування товару"),
        operation: "updateSKU",
        initModel: skuEdit,
      });
    },
    _deleteTemplate(idForDelete) {
      this.$store
        .dispatch("templates/del", {
          contractId: this.$store.getters["contracts/contract"]()._id,
          templateId: idForDelete,
        })
        .then(() => {
          this.$message.success($tt("Шаблони видалено!"));
          if (this.selected.group && this.selected.group.parent_id == idForDelete) this.selected.group = null;
          this.$nextTick(() => {
            this.gridOptionsOrgsDict.api.refreshCells();
            this.gridOptionsOrgs.api.refreshCells();
          });
        })
        .catch((err) => {
          globalErrorMessage($tt("Помилка видалення!"), err);
        });
    },
    deleteSelectedTemplate(event, data) {
      if (this.selected.template || data) {
        this.$confirm($tt("Ви дійсно бажаєте видалити обраний шаблон?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            var idForDelete = data && data._id ? data._id : this.selected.template._id;
            this.$store
              .dispatch("templates/getRestrictsWithThisTemplate", {
                contractId: this.$store.getters["contracts/contract"]()._id,
                templateId: idForDelete,
              })
              .then((data) => {
                if (data && data.data && data.data.length) {
                  this.$confirm(
                    $tt("Цей шаблон задіяно для {restricts} умов і {orgs} структур! Для них шаблон буде скинуто. Продовжити?", {
                      restricts: _.unique(data.data.map((item) => item._id)).length,
                      orgs: _.unique(data.data.map((item) => item.org.axiomaId)).length,
                    }),
                    $tt("Увага"),
                    {
                      confirmButtonText: $tt("Так"),
                      cancelButtonText: $tt("Ні"),
                      type: "warning",
                    }
                  )
                    .then(() => {
                      this._deleteTemplate(idForDelete);
                    })
                    .catch((err) => {});
                } else {
                  this._deleteTemplate(idForDelete);
                }
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка отримання даних про зв'язані умови!"), err);
              });
          })
          .catch((err) => {});
      }
    },
    deleteSelectedGroup(event, data) {
      var that = this;
      if (this.selected.group || data) {
        this.$confirm($tt("Ви дійсно бажаєте видалити обрану групу?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            var idForDelete = data && data._id ? data._id : that.selected.group._id;
            var templateId = data && data._id ? data.parent_id : that.selected.template._id;
            that.$store
              .dispatch("templates/deleteGroup", {
                contractId: that.$store.getters["contracts/contract"]()._id,
                templateId: templateId,
                data: [idForDelete],
              })
              .then(() => {
                that.$message.success($tt("Групу видалено!"));
                if (that.selected.group && that.selected.group._id == idForDelete) that.selected.group = null;
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка видалення!"), err);
              });
          })
          .catch((err) => {});
      }
    },
    deleteSelectedSKU(event, data) {
      if (this.selected.sku || data) {
        this.$confirm($tt("Ви дійсно бажаєте видалити обрані товари із шаблону?"), $tt("Увага"), {
          confirmButtonText: $tt("Так"),
          cancelButtonText: $tt("Ні"),
          type: "warning",
        })
          .then(() => {
            var idForDelete = data && data.drugsId ? [data.drugsId] : this.selected.sku.map((item) => item.drugsId);
            this.$store
              .dispatch("templates/deleteSKU", {
                contractId: this.$store.getters["contracts/contract"]()._id,
                templateId: this.$store.getters["templates/template"]()._id,
                data: idForDelete,
              })
              .then(() => {
                this.$message.success($tt("Товари видалено!"));
                this.selected.sku = null;
                this.gridOptionsSKU.api.refreshCells();
                this.gridOptionsTemplate.api.refreshCells();
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка видалення!"), err);
              });
          })
          .catch((err) => {});
      }
    },
    deleteSelected() {
      if (this.activeTab == "templates") {
        this.deleteSelectedSKU();
      } else {
        this.deleteSelectedOrg();
      }
    },
    deleteSelectedOrg() {
      var idsForDel = [];
      if (this.selected.orgs && this.selected.orgs.length > 0) {
        var idsForDel = this.selected.orgs.map((item) => item.axiomaId);
      } else return;
      this.$confirm($tt("Для видалення обрано {count} структур. Видалити обрані структури?", { count: idsForDel.length }), $tt("Увага"), {
        confirmButtonText: $tt("Так"),
        cancelButtonText: $tt("Ні"),
        type: "warning",
      })
        .then(() => {
          this.$store
            .dispatch("restricts/deleteOrg", {
              contractId: this.$store.getters["contracts/contract"]()._id,
              restrictId: this.$store.getters["restricts/restrict"]()._id,
              data: idsForDel,
            })
            .then(() => {
              this.$message.success($tt("Структури видалено!"));
              this.selected.orgs = null;
              this.gridOptionsOrgs.api.refreshCells();
              var rowNodes = idsForDel.map((axiomaId) => this.gridOptionsOrgsDict.api.getRowNode(axiomaId));
              this.gridOptionsOrgsDict.api.redrawRows({ rowNodes: rowNodes });
            })
            .catch((err) => {
              globalErrorMessage($tt("Помилка видалення!"), err);
            });
        })
        .catch((err) => {});
    },
    handleClick(command) {
      switch (command) {
        case "export-to-json": {
          switch (this.activeTab) {
            case "rules": {
              this.exportRulesToJSON();
              break;
            }
            case "orgs": {
              this.exportOrgsToJSON();
              break;
            }
            case "sku": {
              this.exportSKUToJSON();
              break;
            }
          }
          break;
        }
        case "import-from-groups": {
          this.importSKUFromGroups();
          break;
        }
        case "import-from-json": {
          this.importSKUFromJSON();
          break;
        }
        case "import-from-xlsx": {
          this.importSKUFromXLS();
          break;
        }
        default:
          break;
      }
    },
    onGridColumnsChangeTemplate() {
      if (this.changeColumnsTimerTemplate) clearTimeout(this.changeColumnsTimerTemplate);
      this.changeColumnsTimerTemplate = setTimeout(() => {
        var state = this.gridOptionsTemplate.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimerTemplate);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsSetTemplatesGridColumnState: state });
      }, 500);
    },
    onGridColumnsChangeSKUDict() {
      if (this.changeColumnsTimerTemplate) clearTimeout(this.changeColumnsTimerTemplate);
      this.changeColumnsTimerTemplate = setTimeout(() => {
        var state = this.gridOptionsSKUDict.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimerTemplate);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsSetSKUDictGridColumnState: state });
      }, 500);
    },
    onGridColumnsChangeSKU() {
      if (this.changeColumnsTimerSKU) clearTimeout(this.changeColumnsTimerSKU);
      this.changeColumnsTimerSKU = setTimeout(() => {
        var state = this.gridOptionsSKU.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimerSKU);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsSetSKUGridColumnState: state });
      }, 500);
    },
    onGridColumnsChangeOrgs() {
      if (this.changeColumnsTimerOrgs) clearTimeout(this.changeColumnsTimerOrgs);
      this.changeColumnsTimerOrgs = setTimeout(() => {
        var state = this.gridOptionsOrgs.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimerOrgs);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsSetOrgsGridColumnState: state });
      }, 500);
    },
    onGridColumnsChangeOrgsDict() {
      if (this.changeColumnsTimerOrgsDict) clearTimeout(this.changeColumnsTimerOrgsDict);
      this.changeColumnsTimerOrgsDict = setTimeout(() => {
        var state = this.gridOptionsOrgsDict.columnApi.getColumnState();
        clearTimeout(this.changeColumnsTimerOrgsDict);
        this.$store.dispatch("main/updateUserSettings", { restrictDetailsSetOrgsDictGridColumnState: state });
      }, 500);
    },
    gridLocale(key, defaultValue) {
      var localized = $tt("grid." + key);
      return localized ? localized : defaultValue;
    },
    applyTemplateForSelectedRun() {
      this.$store.dispatch("main/setGlobalLoading", "Оновлення структур...");
      var count = 1;
      this.selected.orgs
        .reduce((prev, org) => {
          return prev.then(() => {
            this.$store.dispatch("main/setGlobalLoading", `Оновлення структур...${count++} із ${this.selected.orgs.length}`);
            this.$store
              .dispatch("restricts/updateOrg", {
                contractId: this.$store.getters["contracts/contract"]()._id,
                restrictId: this.$store.getters["restricts/restrict"]()._id,
                axiomaId: org.axiomaId,
                data: _.extend(org, { templateId: this.template ? this.template._id : null }),
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка оновлення!"), err);
                this.$store.dispatch("main/setGlobalLoading");
              });
          });
        }, Promise.resolve())
        .then(() => {
          this.$message.success($tt("Структури  оновлено!"));
          this.$store.dispatch("main/setGlobalLoading");
          this.gridOptionsOrgs.api.refreshCells();
          this.gridOptionsOrgsDict.api.refreshCells();
        });
    },
    clearTemplateForSelectedRun() {
      this.$store.dispatch("main/setGlobalLoading", "Оновлення структур...");
      var count = 1;
      this.selected.orgs
        .reduce((prev, org) => {
          return prev.then(() => {
            this.$store.dispatch("main/setGlobalLoading", `Оновлення структур...${count++} із ${this.selected.orgs.length}`);
            this.$store
              .dispatch("restricts/updateOrg", {
                contractId: this.$store.getters["contracts/contract"]()._id,
                restrictId: this.$store.getters["restricts/restrict"]()._id,
                axiomaId: org.axiomaId,
                data: _.extend(org, { templateId: null }),
              })
              .catch((err) => {
                globalErrorMessage($tt("Помилка оновлення!"), err);
                this.$store.dispatch("main/setGlobalLoading");
              });
          });
        }, Promise.resolve())
        .then(() => {
          this.$message.success($tt("Структури  оновлено!"));
          this.$store.dispatch("main/setGlobalLoading");
          this.gridOptionsOrgs.api.refreshCells();
        });
    },
    applyTemplateForSelected() {
      if (this.selected.template) {
        this.$confirm(
          $tt("Застосувати шаблон [{name}] для вибраних {count} структур?", { name: this.selected.template.name, count: this.selected.orgs.length }),
          $tt("Увага"),
          {
            confirmButtonText: $tt("Так"),
            cancelButtonText: $tt("Ні"),
            type: "warning",
          }
        )
          .then(() => {
            this.applyTemplateForSelectedRun();
          })
          .catch((err) => {});
      }
    },
    clearTemplateForSelected() {
      this.$confirm($tt("Зкинути шаблони для вибраних {count} структур?", { count: this.selected.orgs.length }), $tt("Увага"), {
        confirmButtonText: $tt("Так"),
        cancelButtonText: $tt("Ні"),
        type: "warning",
      })
        .then(() => {
          this.clearTemplateForSelectedRun();
        })
        .catch((err) => {});
    },
    onKeyDownHandler(e) {
      if (e.keyCode == 13) {
        this.onEnter();
        e.preventDefault();
        return false;
      }
    },
    onEnter() {
      if (!this.selected.template) {
        this.$message.error($tt("Будь ласка, оберіть шаблон!"));
        return;
      }
      if (this.activeTab == "templates" && this.selected.skuDict && this.selected.skuDict.length) {
        this.addNewSKU();
      } else if (this.activeTab == "orgs" && this.selected.orgsDict && this.selected.orgsDict.length) {
        this.addNewOrg();
      }
    },
  },
};
export default Restricts;
</script>

<style lang="scss" scope>
.el-main {
  padding: 0;
  background: #fff;
  padding: 0px !important;
  height: calc(100vh - 164px);
}

.el-date-editor.el-input,
.el-date-editor.el-input__inner {
  width: 136px;
}

.invalid {
  background: rgb(253, 171, 171);
  transition: all 1s;
}

.tab-bar {
  font-size: 17px;
  .el-tabs__item {
    width: 272px;
    color: gray;
  }
  .el-tabs__item.is-active {
    color: #26ae4d;
    font-weight: 700;
  }
  .el-tabs__active-bar {
    width: 272px;
    background-color: #26ae4d;
  }
}
</style>
